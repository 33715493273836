import { useState } from "react"

import {
  MSelectV2,
  TMSelectOptions,
  TMSelectProps,
} from "src/ui/MSelect/MSelectV2"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

type TOmittedMSelectProps = Omit<TMSelectProps, "value" | "onChange">

export function SettingDropdown({
  title,
  description,
  value,
  onSave,
  selectProps,
  options,
  displayValue,
  ...props
}: ISettingContainerBaseProps & {
  selectProps?: TOmittedMSelectProps
  onSave(newVal: string): TSettingContainerOnSaveReturnType
  value: string
  options: TMSelectOptions
  displayValue?: string
}) {
  const [saveValue, setSaveValue] = useState(value)

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={() => {
        return onSave(saveValue)
      }}
      displayValue={displayValue || String(value)}
      InputComponent={({ saving }) => (
        <Input
          valueInitial={value}
          setSaveValue={setSaveValue}
          disabled={saving}
          options={options}
          {...selectProps}
        />
      )}
      {...props}
    />
  )
}

function Input({
  valueInitial,
  setSaveValue,
  ...selectProps
}: {
  valueInitial: string
  setSaveValue(newVal: string): void
} & TOmittedMSelectProps) {
  const [_value, _setValue] = useState(valueInitial)
  return (
    <MSelectV2
      value={_value}
      onChange={(value) => {
        _setValue(value)
        setSaveValue(value)
      }}
      {...selectProps}
    />
  )
}
